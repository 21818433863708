@import '~node_modules/modern-normalize/modern-normalize.css';

:root {
  --bg: #EAEAEA;
  --primary: #393939;
  --secondary: #83DFE6;
  --font-color: #727272;
}

//base
body {
  background-color: var(--bg);
  height: 100%;
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--font-color);
}

h1, h2, h3 {
  font-family: 'Prompt', sans-serif;
  font-weight: 600;
  line-height: 1.16;
  color: var(--primary);
  margin: 0;
}

h1 {
  font-size: 45px;

  @include md {
    font-size: 36px;
  }

  @include xs {
    font-size: 32px;
  }
}

h2 {
  font-size: 36px;

  @include xs {
    font-size: 32px;
  }
}

h3 {
  font-size: 22px;
}

a {
  color: var(--font-color);
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  width: 100%;
  height: auto;
  line-height: 0;
}

section {
  padding: 50px;
  margin: 70px 0;
  border-radius: 50px;
  overflow: hidden;

  @include md {
    margin: 25px 0;
    padding: 25px;
    border-radius: 30px;
  }
}