@import 'breakpoints';
@import './common';
@import '../../node_modules/intl-tel-input/build/css/intlTelInput.min.css';

.container {
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
  height: inherit;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex-grow: 1;
  margin-top: 20px;

  &_container {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 150px;
      border-radius: 40px 40px 0 0;
      background-color: #fff;
    }
  }
}

.d-flex {
  display: flex;
  flex-wrap: wrap;

  @include md {
    flex-direction: column;
  }
}

.btn {
  padding: 10px 25px;
  border-radius: 100px;
  transition: .3s;
  cursor: pointer;

  &_primary {
    border: 1px solid var(--primary);
    background-color: var(--primary);
    color: #fff;

    &:hover:not(:disabled) {
      border-color: var(--secondary);
      background-color: var(--secondary);
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
    }

    &:focus {
      border-color: var(--secondary);
      background-color: var(--secondary);
    }

    &:disabled {
      cursor: not-allowed;
      background-color: #EAEAEA;
      border-color: #EAEAEA;
    }
  }

  &_secondary {
    background-color: #fff;
    color: var(--primary);
    border: 1px solid var(--primary);

    &:hover {
      color: #fff;
      background-color: var(--primary);
    }
  }
}

.underline {
  display: inline-flex;
  position: relative;

  &:after {
    content: '';
    width: 103%;
    height: 3px;
    border-radius: 3px;
    background-color: var(--secondary);
    left: -3px;
    bottom: 0;
    position: absolute;
  }
}

.colored {
  color: var(--secondary);
}

.reverse {
  flex-direction: row-reverse;

  @include md {
    flex-direction: column;
  }
}

.card {
  padding: 25px 5px 25px 25px;
  border-radius: 20px;
  display: flex;
  align-items: flex-end;

  @include sm {
    flex-direction: column;
    padding: 25px;
  }

  &_content {
    flex-grow: 1;
    align-self: flex-start;
  }

  &_title {
    margin-bottom: 10px;
  }

  &_icon {
    width: 90px;
    min-width: 90px;
    height: 90px;
    padding-right: 10px;
    position: relative;

    img {
      position: absolute;
      right: 0;
      bottom: -25px;

      @include sm {
        position: static;
      }
    }
  }

  p {
    margin: 0;
    font-size: 14px;
    line-height: 1.4;
    color: var(--primary);
  }
}

.blue {
  background-color: var(--secondary);
}

.white {
  background-color: #fff;
}

.green {
  background-color: #32C66A;
}

.yellow {
  background-color: #FFE45E;
}

//header
.header {
  background-color: #fff;
  border-radius: 50px;
  padding: 15px 50px;
  position: sticky;
  left: 0;
  top: 20px;
  width: 100%;
  z-index: 999;

  @include md {
    padding: 15px 25px;
    position: static;
  }

  &_wrap {
    @include md {
      position: fixed;
      left: -100%;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, .5);
      transform: translateX(0);
      transition: .3s;
      z-index: 99;
    }

    &.visible {
      transform: translateX(100%);
    }
  }

  &_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include md {
      flex-direction: column;
      align-items: flex-start;
      height: inherit;
      width: 100%;
      max-width: 350px;
      background-color: #fff;
      padding: 70px 20px 35px 35px;
      border-radius: 0 50px 50px 0;
    }
  }

  &_list {
    display: flex;

    @include md {
      flex-direction: column;
    }
  }

  &_item {
    padding: 10px 12px;
    margin: 0 8px;
    transition: .3s;

    @include md {
      margin: 0;
    }

    &:hover {
      background-color: #EAEAEA;
      border-radius: 50px;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &_action {
    padding: 10px 12px;
    transition: .3s;
    cursor: pointer;

    &:hover {
      background-color: #EAEAEA;
      border-radius: 50px;
    }
  }

  &_burger {
    width: 30px;
    height: 24px;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    z-index: 999;
    display: none;

    @include md {
      display: block;
    }

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 50%;
      background: var(--primary);
      opacity: 1;
      transform: rotate(0deg);
      transition: .25s ease-in-out;

      &:nth-child(even) {
        left: 50%;
      }

      &:nth-child(odd) {
        left: 0;
      }

      &:first-child,
      &:nth-child(2) {
        top: 0;
      }

      &:nth-child(3),
      &:nth-child(4) {
        top: 10px;
      }

      &:nth-child(5),
      &:nth-child(6) {
        top: 20px;
      }
    }

    &.open {
      span {
        &:first-child,
        &:last-child {
          transform: rotate(45deg);
        }

        &:nth-child(2),
        &:nth-child(5) {
          transform: rotate(-45deg);
        }

        &:first-child {
          left: 5px;
          top: 5px;
        }

        &:nth-child(2) {
          left: calc(50% - 5px);
          top: 5px;
        }

        &:nth-child(3) {
          left: -50%;
          opacity: 0;
        }

        &:nth-child(4) {
          left: 100%;
          opacity: 0;
        }

        &:nth-child(5) {
          left: 5px;
          top: 10px;
        }

        &:nth-child(6) {
          left: calc(50% - 5px);
          top: 10px;
        }
      }
    }
  }
}

//hero
.hero {
  background-color: #fff;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-top: 0;

  &:after {
    content: '';
    background: url("../images/gradient.png") no-repeat;
    position: absolute;
    bottom: 0;
    right: -3px;
    width: 250px;
    height: 250px;
  }

  &_content {
    align-items: center;
  }

  &_desc {
    width: 60%;

    @include md {
      width: 100%;
    }

    p {
      margin: 15px 0 25px;
      font-family: 'Prompt', sans-serif;
    }
  }

  &_img {
    width: 40%;

    @include md {
      margin-top: 15px;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    img {
      @include md {
        max-width: 400px;
      }
    }
  }
}

//about
.about {
  background: rgba(255, 255, 255, 0.5);

  &_item {
    width: 50%;

    @include md {
      width: 100%;
    }

    &:first-child {
      padding-right: 25px;

      @include md {
        padding-right: 0;
      }
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

//custom
.custom {
  background-color: #fff;
  position: relative;

  &:after {
    content: '';
    background: url("../images/gradient.png") no-repeat;
    position: absolute;
    transform: rotateY(180deg);
    bottom: 0;
    left: -3px;
    width: 250px;
    height: 250px;
  }

  &_item {
    width: 50%;

    @include md {
      width: 100%;
    }
  }

  &_head {
    span {
      color: var(--secondary);
    }
  }

  &_desc {
    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &_img {
    max-width: 400px;
    margin-left: auto;
    z-index: 1;
    position: relative;
    border-radius: 25px;
    line-height: 0;
    overflow: hidden;

    @include md {
      margin: 0 auto;
    }
  }
}

//features
.features {
  background-color: rgba(255, 255, 255, .5);

  &_head {
    width: 70%;

    @include md {
      width: 100%;
    }
  }

  &_grid {
    margin: 0 -8px;

    @include md {
      margin: 0;
    }
  }

  &_card {

    &-lg {
      width: 100%;
      margin: 15px 0;

      @include md {
        margin: 0;
      }
    }

    &-sm {
      width: calc((100% / 3) - 16px);

      @include lg {
        width: calc(50% - 16px);
      }
    }

    &-md {
      width: calc(50% - 16px);
    }

    &-sm,
    &-md {
      margin: 8px;

      @include md {
        margin: 0;
        width: 100%;
      }
    }
  }
}

//benefits
.benefits {
  background-color: #fff;

  &_list {
    width: 75%;
    margin-top: 15px;

    @include md {
      width: 100%;
    }

    li {
      padding: 0 15px;
      margin: 15px 0;
      position: relative;
      color: var(--primary);
      font-family: 'Prompt', sans-serif;
      font-weight: 500;
      font-size: 22px;
      line-height: 1.27;

      &:before {
        content: '';
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: var(--primary);
        left: 0;
        top: 13px;
      }
    }
  }

  &_img {
    width: 25%;

    @include md {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    img {
      @include md {
        max-width: 400px;
      }
    }
  }
}

//case
.case {
  background-color: #fff;
  position: relative;
  padding-bottom: 0;

  @include md {
    padding-bottom: 25px;
  }

  &:after {
    content: '';
    background: url("../images/gradient.png") no-repeat;
    position: absolute;
    bottom: 0;
    right: -3px;
    width: 250px;
    height: 250px;
  }

  &_item {
    width: 50%;
    display: flex;

    @include md {
      width: 100%;
    }
  }

  &_img {
    line-height: 0;
    z-index: 1;
  }

  &_desc {
    &-centered {
      align-self: center;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

//solutions
.solutions {
  background-color: var(--secondary);

  &_content {
    align-items: center;
    justify-content: space-between;

    @include md {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &_actions {
    @include md {
      margin-top: 15px;
    }
  }
}

//footer
.footer {
  background: var(--primary);
  padding: 15px 0;
  color: #fff;
  text-align: center;

  @include md {
    text-align: left;
  }

  &_list {
    display: flex;
    justify-content: center;

    @include md {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    li {
      padding: 5px 10px;
      margin: 0 10px;
    }

    a {
      color: #fff;
    }
  }

  &_copy {
    p {
      margin-bottom: 0;
    }
  }
}

//modals
.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;

  &_content {
    position: relative;
    background-color: #fff;
    padding: 50px 100px;
    border-radius: 50px;

    .modal_form & {
      display: flex;
      max-width: 900px;
      width: 100%;
      overflow: hidden;
      align-items: center;

      @include md {
        flex-direction: column-reverse;
        align-items: center;
        padding: 40px 20px;
      }

      @include xs {
        padding: 20px;
      }

      &:after {
        content: '';
        background: url("../images/gradient.png") no-repeat;
        position: absolute;
        transform: rotateY(180deg);
        bottom: 0;
        left: -3px;
        width: 250px;
        height: 250px;

        @include sm {
          display: none;
        }
      }
    }

    .modal_thanks & {
      max-width: 500px;
    }
  }

  &_show {
    z-index: 99999;
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
  }

  &_image,
  &_contact {
    width: 50%;

    @include md {
      width: 100%;
    }
  }

  &_image {
    display: flex;

    @include sm {
      display: none;
    }

    img {
      max-width: 250px;
      margin-right: auto;

      @include md {
        max-width: 150px;
        margin: 0 auto;
      }
    }
  }

  &_title {
    text-align: center;
    margin-bottom: 50px;

    @include xs {
      margin-bottom: 20px;
    }

    h2 {
      margin-bottom: 15px;
    }
  }

  &_thanks {
    .modal {
      &_title {
        margin-bottom: 15px;
      }
    }

    .btn {
      margin-top: 50px;
    }
  }
}

.contact {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;

  &_control {
    margin-bottom: 25px;
    position: relative;

    & label {
      position: absolute;
      display: flex;
      padding: 0 5px;
      background-color: #fff;
      font-size: 12px;
      color: var(--font-color);
      line-height: 1;
      z-index: 1;
      top: -6px;
      left: 8px;
    }
  }

  &_field {
    width: 100%;
    padding: 12px 15px;
    font-size: 14px;
    border-radius: 10px;
    border: 1px solid var(--primary);

    &:focus {
      outline: 1px solid var(--secondary);
      border: 1px solid var(--secondary);
    }

    @include md {
      &:focus {
        outline: none !important;
      }
    }

    &.phone {
      padding-left: 92px !important;
    }
  }

  .label {
    opacity: 0;
    transition: .1s;

    &.visible {
      opacity: 1;
    }
  }

  &_action {
    margin-top: 50px;
    display: flex;
    justify-content: center;

    @include xs {
      margin-top: 30px;
    }

    .btn {
      margin: 0 7px;
    }
  }
}

//phone field
.iti {
  width: 100%;

  &-mobile {
    .iti--container {
      z-index: 99999;
    }
  }

  &__country-list {
    max-width: 320px;
    bottom: 0;
    max-height: 250px;
  }

  &--separate-dial-code,
  &__selected-flag {
    background-color: transparent !important;
  }
}


