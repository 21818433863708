*, :before, :after {
  box-sizing: border-box;
}

html {
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: system-ui, AppleSystem, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, AppleSystem, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

.iti {
  display: inline-block;
  position: relative;
}

.iti * {
  box-sizing: border-box;
}

.iti__hide {
  display: none;
}

.iti__v-hide {
  visibility: hidden;
}

.iti input, .iti input[type="tel"], .iti input[type="text"] {
  z-index: 0;
  margin-right: 0;
  padding-right: 36px;
  position: relative;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.iti__flag-container {
  padding: 1px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.iti__selected-flag {
  z-index: 1;
  height: 100%;
  align-items: center;
  padding: 0 6px 0 8px;
  display: flex;
  position: relative;
}

.iti__arrow {
  width: 0;
  height: 0;
  border-top: 4px solid #555;
  border-left: 3px solid rgba(0, 0, 0, 0);
  border-right: 3px solid rgba(0, 0, 0, 0);
  margin-left: 6px;
}

[dir="rtl"] .iti__arrow {
  margin-left: 0;
  margin-right: 6px;
}

.iti__arrow--up {
  border-top: none;
  border-bottom: 4px solid #555;
}

.iti__country-list {
  z-index: 2;
  white-space: nowrap;
  max-height: 200px;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  border: 1px solid #ccc;
  margin: 0 0 0 -1px;
  padding: 0;
  list-style: none;
  position: absolute;
  overflow-y: scroll;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, .2);
}

.iti__country-list--dropup {
  margin-bottom: -1px;
  bottom: 100%;
}

@media (max-width: 500px) {
  .iti__country-list {
    white-space: normal;
  }
}

.iti__flag-box {
  width: 20px;
  display: inline-block;
}

.iti__divider {
  border-bottom: 1px solid #ccc;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.iti__country {
  outline: 0;
  align-items: center;
  padding: 5px 10px;
  display: flex;
}

.iti__dial-code {
  color: #999;
}

.iti__country.iti__highlight {
  background-color: rgba(0, 0, 0, .05);
}

.iti__country-name, .iti__flag-box {
  margin-right: 6px;
}

[dir="rtl"] .iti__country-name, [dir="rtl"] .iti__flag-box {
  margin-left: 6px;
  margin-right: 0;
}

.iti--allow-dropdown input, .iti--allow-dropdown input[type="tel"], .iti--allow-dropdown input[type="text"], .iti--separate-dial-code input, .iti--separate-dial-code input[type="tel"], .iti--separate-dial-code input[type="text"] {
  margin-left: 0;
  padding-left: 52px;
  padding-right: 6px;
}

[dir="rtl"] .iti--allow-dropdown input, [dir="rtl"] .iti--allow-dropdown input[type="tel"], [dir="rtl"] .iti--allow-dropdown input[type="text"], [dir="rtl"] .iti--separate-dial-code input, [dir="rtl"] .iti--separate-dial-code input[type="tel"], [dir="rtl"] .iti--separate-dial-code input[type="text"] {
  margin-right: 0;
  padding-left: 6px;
  padding-right: 52px;
}

.iti--allow-dropdown .iti__flag-container, .iti--separate-dial-code .iti__flag-container {
  left: 0;
  right: auto;
}

[dir="rtl"] .iti--allow-dropdown .iti__flag-container, [dir="rtl"] .iti--separate-dial-code .iti__flag-container {
  left: auto;
  right: 0;
}

.iti--allow-dropdown .iti__flag-container:hover {
  cursor: pointer;
}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: rgba(0, 0, 0, .05);
}

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover, .iti--allow-dropdown input[readonly] + .iti__flag-container:hover {
  cursor: default;
}

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover .iti__selected-flag, .iti--allow-dropdown input[readonly] + .iti__flag-container:hover .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0);
}

.iti--separate-dial-code .iti__selected-flag {
  background-color: rgba(0, 0, 0, .05);
}

.iti--separate-dial-code .iti__selected-dial-code {
  margin-left: 6px;
}

.iti--container {
  z-index: 1060;
  padding: 1px;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.iti--container:hover {
  cursor: pointer;
}

.iti-mobile .iti--container {
  position: fixed;
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
}

.iti-mobile .iti__country-list {
  max-height: 100%;
  width: 100%;
}

.iti-mobile .iti__country {
  padding: 10px;
  line-height: 1.5em;
}

.iti__flag {
  width: 20px;
}

.iti__flag.iti__be {
  width: 18px;
}

.iti__flag.iti__ch {
  width: 15px;
}

.iti__flag.iti__mc {
  width: 19px;
}

.iti__flag.iti__ne {
  width: 18px;
}

.iti__flag.iti__np {
  width: 13px;
}

.iti__flag.iti__va {
  width: 15px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-size: 5652px 15px;
  }
}

.iti__flag.iti__ac {
  height: 10px;
  background-position: 0 0;
}

.iti__flag.iti__ad {
  height: 14px;
  background-position: -22px 0;
}

.iti__flag.iti__ae {
  height: 10px;
  background-position: -44px 0;
}

.iti__flag.iti__af {
  height: 14px;
  background-position: -66px 0;
}

.iti__flag.iti__ag {
  height: 14px;
  background-position: -88px 0;
}

.iti__flag.iti__ai {
  height: 10px;
  background-position: -110px 0;
}

.iti__flag.iti__al {
  height: 15px;
  background-position: -132px 0;
}

.iti__flag.iti__am {
  height: 10px;
  background-position: -154px 0;
}

.iti__flag.iti__ao {
  height: 14px;
  background-position: -176px 0;
}

.iti__flag.iti__aq {
  height: 14px;
  background-position: -198px 0;
}

.iti__flag.iti__ar {
  height: 13px;
  background-position: -220px 0;
}

.iti__flag.iti__as {
  height: 10px;
  background-position: -242px 0;
}

.iti__flag.iti__at {
  height: 14px;
  background-position: -264px 0;
}

.iti__flag.iti__au {
  height: 10px;
  background-position: -286px 0;
}

.iti__flag.iti__aw {
  height: 14px;
  background-position: -308px 0;
}

.iti__flag.iti__ax {
  height: 13px;
  background-position: -330px 0;
}

.iti__flag.iti__az {
  height: 10px;
  background-position: -352px 0;
}

.iti__flag.iti__ba {
  height: 10px;
  background-position: -374px 0;
}

.iti__flag.iti__bb {
  height: 14px;
  background-position: -396px 0;
}

.iti__flag.iti__bd {
  height: 12px;
  background-position: -418px 0;
}

.iti__flag.iti__be {
  height: 15px;
  background-position: -440px 0;
}

.iti__flag.iti__bf {
  height: 14px;
  background-position: -460px 0;
}

.iti__flag.iti__bg {
  height: 12px;
  background-position: -482px 0;
}

.iti__flag.iti__bh {
  height: 12px;
  background-position: -504px 0;
}

.iti__flag.iti__bi {
  height: 12px;
  background-position: -526px 0;
}

.iti__flag.iti__bj {
  height: 14px;
  background-position: -548px 0;
}

.iti__flag.iti__bl {
  height: 14px;
  background-position: -570px 0;
}

.iti__flag.iti__bm {
  height: 10px;
  background-position: -592px 0;
}

.iti__flag.iti__bn {
  height: 10px;
  background-position: -614px 0;
}

.iti__flag.iti__bo {
  height: 14px;
  background-position: -636px 0;
}

.iti__flag.iti__bq {
  height: 14px;
  background-position: -658px 0;
}

.iti__flag.iti__br {
  height: 14px;
  background-position: -680px 0;
}

.iti__flag.iti__bs {
  height: 10px;
  background-position: -702px 0;
}

.iti__flag.iti__bt {
  height: 14px;
  background-position: -724px 0;
}

.iti__flag.iti__bv {
  height: 15px;
  background-position: -746px 0;
}

.iti__flag.iti__bw {
  height: 14px;
  background-position: -768px 0;
}

.iti__flag.iti__by {
  height: 10px;
  background-position: -790px 0;
}

.iti__flag.iti__bz {
  height: 14px;
  background-position: -812px 0;
}

.iti__flag.iti__ca {
  height: 10px;
  background-position: -834px 0;
}

.iti__flag.iti__cc {
  height: 10px;
  background-position: -856px 0;
}

.iti__flag.iti__cd {
  height: 15px;
  background-position: -878px 0;
}

.iti__flag.iti__cf {
  height: 14px;
  background-position: -900px 0;
}

.iti__flag.iti__cg {
  height: 14px;
  background-position: -922px 0;
}

.iti__flag.iti__ch {
  height: 15px;
  background-position: -944px 0;
}

.iti__flag.iti__ci {
  height: 14px;
  background-position: -961px 0;
}

.iti__flag.iti__ck {
  height: 10px;
  background-position: -983px 0;
}

.iti__flag.iti__cl {
  height: 14px;
  background-position: -1005px 0;
}

.iti__flag.iti__cm {
  height: 14px;
  background-position: -1027px 0;
}

.iti__flag.iti__cn {
  height: 14px;
  background-position: -1049px 0;
}

.iti__flag.iti__co {
  height: 14px;
  background-position: -1071px 0;
}

.iti__flag.iti__cp {
  height: 14px;
  background-position: -1093px 0;
}

.iti__flag.iti__cr {
  height: 12px;
  background-position: -1115px 0;
}

.iti__flag.iti__cu {
  height: 10px;
  background-position: -1137px 0;
}

.iti__flag.iti__cv {
  height: 12px;
  background-position: -1159px 0;
}

.iti__flag.iti__cw {
  height: 14px;
  background-position: -1181px 0;
}

.iti__flag.iti__cx {
  height: 10px;
  background-position: -1203px 0;
}

.iti__flag.iti__cy {
  height: 14px;
  background-position: -1225px 0;
}

.iti__flag.iti__cz {
  height: 14px;
  background-position: -1247px 0;
}

.iti__flag.iti__de {
  height: 12px;
  background-position: -1269px 0;
}

.iti__flag.iti__dg {
  height: 10px;
  background-position: -1291px 0;
}

.iti__flag.iti__dj {
  height: 14px;
  background-position: -1313px 0;
}

.iti__flag.iti__dk {
  height: 15px;
  background-position: -1335px 0;
}

.iti__flag.iti__dm {
  height: 10px;
  background-position: -1357px 0;
}

.iti__flag.iti__do {
  height: 14px;
  background-position: -1379px 0;
}

.iti__flag.iti__dz {
  height: 14px;
  background-position: -1401px 0;
}

.iti__flag.iti__ea {
  height: 14px;
  background-position: -1423px 0;
}

.iti__flag.iti__ec {
  height: 14px;
  background-position: -1445px 0;
}

.iti__flag.iti__ee {
  height: 13px;
  background-position: -1467px 0;
}

.iti__flag.iti__eg {
  height: 14px;
  background-position: -1489px 0;
}

.iti__flag.iti__eh {
  height: 10px;
  background-position: -1511px 0;
}

.iti__flag.iti__er {
  height: 10px;
  background-position: -1533px 0;
}

.iti__flag.iti__es {
  height: 14px;
  background-position: -1555px 0;
}

.iti__flag.iti__et {
  height: 10px;
  background-position: -1577px 0;
}

.iti__flag.iti__eu {
  height: 14px;
  background-position: -1599px 0;
}

.iti__flag.iti__fi {
  height: 12px;
  background-position: -1621px 0;
}

.iti__flag.iti__fj {
  height: 10px;
  background-position: -1643px 0;
}

.iti__flag.iti__fk {
  height: 10px;
  background-position: -1665px 0;
}

.iti__flag.iti__fm {
  height: 11px;
  background-position: -1687px 0;
}

.iti__flag.iti__fo {
  height: 15px;
  background-position: -1709px 0;
}

.iti__flag.iti__fr {
  height: 14px;
  background-position: -1731px 0;
}

.iti__flag.iti__ga {
  height: 15px;
  background-position: -1753px 0;
}

.iti__flag.iti__gb {
  height: 10px;
  background-position: -1775px 0;
}

.iti__flag.iti__gd {
  height: 12px;
  background-position: -1797px 0;
}

.iti__flag.iti__ge {
  height: 14px;
  background-position: -1819px 0;
}

.iti__flag.iti__gf {
  height: 14px;
  background-position: -1841px 0;
}

.iti__flag.iti__gg {
  height: 14px;
  background-position: -1863px 0;
}

.iti__flag.iti__gh {
  height: 14px;
  background-position: -1885px 0;
}

.iti__flag.iti__gi {
  height: 10px;
  background-position: -1907px 0;
}

.iti__flag.iti__gl {
  height: 14px;
  background-position: -1929px 0;
}

.iti__flag.iti__gm {
  height: 14px;
  background-position: -1951px 0;
}

.iti__flag.iti__gn {
  height: 14px;
  background-position: -1973px 0;
}

.iti__flag.iti__gp {
  height: 14px;
  background-position: -1995px 0;
}

.iti__flag.iti__gq {
  height: 14px;
  background-position: -2017px 0;
}

.iti__flag.iti__gr {
  height: 14px;
  background-position: -2039px 0;
}

.iti__flag.iti__gs {
  height: 10px;
  background-position: -2061px 0;
}

.iti__flag.iti__gt {
  height: 13px;
  background-position: -2083px 0;
}

.iti__flag.iti__gu {
  height: 11px;
  background-position: -2105px 0;
}

.iti__flag.iti__gw {
  height: 10px;
  background-position: -2127px 0;
}

.iti__flag.iti__gy {
  height: 12px;
  background-position: -2149px 0;
}

.iti__flag.iti__hk {
  height: 14px;
  background-position: -2171px 0;
}

.iti__flag.iti__hm {
  height: 10px;
  background-position: -2193px 0;
}

.iti__flag.iti__hn {
  height: 10px;
  background-position: -2215px 0;
}

.iti__flag.iti__hr {
  height: 10px;
  background-position: -2237px 0;
}

.iti__flag.iti__ht {
  height: 12px;
  background-position: -2259px 0;
}

.iti__flag.iti__hu {
  height: 10px;
  background-position: -2281px 0;
}

.iti__flag.iti__ic {
  height: 14px;
  background-position: -2303px 0;
}

.iti__flag.iti__id {
  height: 14px;
  background-position: -2325px 0;
}

.iti__flag.iti__ie {
  height: 10px;
  background-position: -2347px 0;
}

.iti__flag.iti__il {
  height: 15px;
  background-position: -2369px 0;
}

.iti__flag.iti__im {
  height: 10px;
  background-position: -2391px 0;
}

.iti__flag.iti__in {
  height: 14px;
  background-position: -2413px 0;
}

.iti__flag.iti__io {
  height: 10px;
  background-position: -2435px 0;
}

.iti__flag.iti__iq {
  height: 14px;
  background-position: -2457px 0;
}

.iti__flag.iti__ir {
  height: 12px;
  background-position: -2479px 0;
}

.iti__flag.iti__is {
  height: 15px;
  background-position: -2501px 0;
}

.iti__flag.iti__it {
  height: 14px;
  background-position: -2523px 0;
}

.iti__flag.iti__je {
  height: 12px;
  background-position: -2545px 0;
}

.iti__flag.iti__jm {
  height: 10px;
  background-position: -2567px 0;
}

.iti__flag.iti__jo {
  height: 10px;
  background-position: -2589px 0;
}

.iti__flag.iti__jp {
  height: 14px;
  background-position: -2611px 0;
}

.iti__flag.iti__ke {
  height: 14px;
  background-position: -2633px 0;
}

.iti__flag.iti__kg {
  height: 12px;
  background-position: -2655px 0;
}

.iti__flag.iti__kh {
  height: 13px;
  background-position: -2677px 0;
}

.iti__flag.iti__ki {
  height: 10px;
  background-position: -2699px 0;
}

.iti__flag.iti__km {
  height: 12px;
  background-position: -2721px 0;
}

.iti__flag.iti__kn {
  height: 14px;
  background-position: -2743px 0;
}

.iti__flag.iti__kp {
  height: 10px;
  background-position: -2765px 0;
}

.iti__flag.iti__kr {
  height: 14px;
  background-position: -2787px 0;
}

.iti__flag.iti__kw {
  height: 10px;
  background-position: -2809px 0;
}

.iti__flag.iti__ky {
  height: 10px;
  background-position: -2831px 0;
}

.iti__flag.iti__kz {
  height: 10px;
  background-position: -2853px 0;
}

.iti__flag.iti__la {
  height: 14px;
  background-position: -2875px 0;
}

.iti__flag.iti__lb {
  height: 14px;
  background-position: -2897px 0;
}

.iti__flag.iti__lc {
  height: 10px;
  background-position: -2919px 0;
}

.iti__flag.iti__li {
  height: 12px;
  background-position: -2941px 0;
}

.iti__flag.iti__lk {
  height: 10px;
  background-position: -2963px 0;
}

.iti__flag.iti__lr {
  height: 11px;
  background-position: -2985px 0;
}

.iti__flag.iti__ls {
  height: 14px;
  background-position: -3007px 0;
}

.iti__flag.iti__lt {
  height: 12px;
  background-position: -3029px 0;
}

.iti__flag.iti__lu {
  height: 12px;
  background-position: -3051px 0;
}

.iti__flag.iti__lv {
  height: 10px;
  background-position: -3073px 0;
}

.iti__flag.iti__ly {
  height: 10px;
  background-position: -3095px 0;
}

.iti__flag.iti__ma {
  height: 14px;
  background-position: -3117px 0;
}

.iti__flag.iti__mc {
  height: 15px;
  background-position: -3139px 0;
}

.iti__flag.iti__md {
  height: 10px;
  background-position: -3160px 0;
}

.iti__flag.iti__me {
  height: 10px;
  background-position: -3182px 0;
}

.iti__flag.iti__mf {
  height: 14px;
  background-position: -3204px 0;
}

.iti__flag.iti__mg {
  height: 14px;
  background-position: -3226px 0;
}

.iti__flag.iti__mh {
  height: 11px;
  background-position: -3248px 0;
}

.iti__flag.iti__mk {
  height: 10px;
  background-position: -3270px 0;
}

.iti__flag.iti__ml {
  height: 14px;
  background-position: -3292px 0;
}

.iti__flag.iti__mm {
  height: 14px;
  background-position: -3314px 0;
}

.iti__flag.iti__mn {
  height: 10px;
  background-position: -3336px 0;
}

.iti__flag.iti__mo {
  height: 14px;
  background-position: -3358px 0;
}

.iti__flag.iti__mp {
  height: 10px;
  background-position: -3380px 0;
}

.iti__flag.iti__mq {
  height: 14px;
  background-position: -3402px 0;
}

.iti__flag.iti__mr {
  height: 14px;
  background-position: -3424px 0;
}

.iti__flag.iti__ms {
  height: 10px;
  background-position: -3446px 0;
}

.iti__flag.iti__mt {
  height: 14px;
  background-position: -3468px 0;
}

.iti__flag.iti__mu {
  height: 14px;
  background-position: -3490px 0;
}

.iti__flag.iti__mv {
  height: 14px;
  background-position: -3512px 0;
}

.iti__flag.iti__mw {
  height: 14px;
  background-position: -3534px 0;
}

.iti__flag.iti__mx {
  height: 12px;
  background-position: -3556px 0;
}

.iti__flag.iti__my {
  height: 10px;
  background-position: -3578px 0;
}

.iti__flag.iti__mz {
  height: 14px;
  background-position: -3600px 0;
}

.iti__flag.iti__na {
  height: 14px;
  background-position: -3622px 0;
}

.iti__flag.iti__nc {
  height: 10px;
  background-position: -3644px 0;
}

.iti__flag.iti__ne {
  height: 15px;
  background-position: -3666px 0;
}

.iti__flag.iti__nf {
  height: 10px;
  background-position: -3686px 0;
}

.iti__flag.iti__ng {
  height: 10px;
  background-position: -3708px 0;
}

.iti__flag.iti__ni {
  height: 12px;
  background-position: -3730px 0;
}

.iti__flag.iti__nl {
  height: 14px;
  background-position: -3752px 0;
}

.iti__flag.iti__no {
  height: 15px;
  background-position: -3774px 0;
}

.iti__flag.iti__np {
  height: 15px;
  background-position: -3796px 0;
}

.iti__flag.iti__nr {
  height: 10px;
  background-position: -3811px 0;
}

.iti__flag.iti__nu {
  height: 10px;
  background-position: -3833px 0;
}

.iti__flag.iti__nz {
  height: 10px;
  background-position: -3855px 0;
}

.iti__flag.iti__om {
  height: 10px;
  background-position: -3877px 0;
}

.iti__flag.iti__pa {
  height: 14px;
  background-position: -3899px 0;
}

.iti__flag.iti__pe {
  height: 14px;
  background-position: -3921px 0;
}

.iti__flag.iti__pf {
  height: 14px;
  background-position: -3943px 0;
}

.iti__flag.iti__pg {
  height: 15px;
  background-position: -3965px 0;
}

.iti__flag.iti__ph {
  height: 10px;
  background-position: -3987px 0;
}

.iti__flag.iti__pk {
  height: 14px;
  background-position: -4009px 0;
}

.iti__flag.iti__pl {
  height: 13px;
  background-position: -4031px 0;
}

.iti__flag.iti__pm {
  height: 14px;
  background-position: -4053px 0;
}

.iti__flag.iti__pn {
  height: 10px;
  background-position: -4075px 0;
}

.iti__flag.iti__pr {
  height: 14px;
  background-position: -4097px 0;
}

.iti__flag.iti__ps {
  height: 10px;
  background-position: -4119px 0;
}

.iti__flag.iti__pt {
  height: 14px;
  background-position: -4141px 0;
}

.iti__flag.iti__pw {
  height: 13px;
  background-position: -4163px 0;
}

.iti__flag.iti__py {
  height: 11px;
  background-position: -4185px 0;
}

.iti__flag.iti__qa {
  height: 8px;
  background-position: -4207px 0;
}

.iti__flag.iti__re {
  height: 14px;
  background-position: -4229px 0;
}

.iti__flag.iti__ro {
  height: 14px;
  background-position: -4251px 0;
}

.iti__flag.iti__rs {
  height: 14px;
  background-position: -4273px 0;
}

.iti__flag.iti__ru {
  height: 14px;
  background-position: -4295px 0;
}

.iti__flag.iti__rw {
  height: 14px;
  background-position: -4317px 0;
}

.iti__flag.iti__sa {
  height: 14px;
  background-position: -4339px 0;
}

.iti__flag.iti__sb {
  height: 10px;
  background-position: -4361px 0;
}

.iti__flag.iti__sc {
  height: 10px;
  background-position: -4383px 0;
}

.iti__flag.iti__sd {
  height: 10px;
  background-position: -4405px 0;
}

.iti__flag.iti__se {
  height: 13px;
  background-position: -4427px 0;
}

.iti__flag.iti__sg {
  height: 14px;
  background-position: -4449px 0;
}

.iti__flag.iti__sh {
  height: 10px;
  background-position: -4471px 0;
}

.iti__flag.iti__si {
  height: 10px;
  background-position: -4493px 0;
}

.iti__flag.iti__sj {
  height: 15px;
  background-position: -4515px 0;
}

.iti__flag.iti__sk {
  height: 14px;
  background-position: -4537px 0;
}

.iti__flag.iti__sl {
  height: 14px;
  background-position: -4559px 0;
}

.iti__flag.iti__sm {
  height: 15px;
  background-position: -4581px 0;
}

.iti__flag.iti__sn {
  height: 14px;
  background-position: -4603px 0;
}

.iti__flag.iti__so {
  height: 14px;
  background-position: -4625px 0;
}

.iti__flag.iti__sr {
  height: 14px;
  background-position: -4647px 0;
}

.iti__flag.iti__ss {
  height: 10px;
  background-position: -4669px 0;
}

.iti__flag.iti__st {
  height: 10px;
  background-position: -4691px 0;
}

.iti__flag.iti__sv {
  height: 12px;
  background-position: -4713px 0;
}

.iti__flag.iti__sx {
  height: 14px;
  background-position: -4735px 0;
}

.iti__flag.iti__sy {
  height: 14px;
  background-position: -4757px 0;
}

.iti__flag.iti__sz {
  height: 14px;
  background-position: -4779px 0;
}

.iti__flag.iti__ta {
  height: 10px;
  background-position: -4801px 0;
}

.iti__flag.iti__tc {
  height: 10px;
  background-position: -4823px 0;
}

.iti__flag.iti__td {
  height: 14px;
  background-position: -4845px 0;
}

.iti__flag.iti__tf {
  height: 14px;
  background-position: -4867px 0;
}

.iti__flag.iti__tg {
  height: 13px;
  background-position: -4889px 0;
}

.iti__flag.iti__th {
  height: 14px;
  background-position: -4911px 0;
}

.iti__flag.iti__tj {
  height: 10px;
  background-position: -4933px 0;
}

.iti__flag.iti__tk {
  height: 10px;
  background-position: -4955px 0;
}

.iti__flag.iti__tl {
  height: 10px;
  background-position: -4977px 0;
}

.iti__flag.iti__tm {
  height: 14px;
  background-position: -4999px 0;
}

.iti__flag.iti__tn {
  height: 14px;
  background-position: -5021px 0;
}

.iti__flag.iti__to {
  height: 10px;
  background-position: -5043px 0;
}

.iti__flag.iti__tr {
  height: 14px;
  background-position: -5065px 0;
}

.iti__flag.iti__tt {
  height: 12px;
  background-position: -5087px 0;
}

.iti__flag.iti__tv {
  height: 10px;
  background-position: -5109px 0;
}

.iti__flag.iti__tw {
  height: 14px;
  background-position: -5131px 0;
}

.iti__flag.iti__tz {
  height: 14px;
  background-position: -5153px 0;
}

.iti__flag.iti__ua {
  height: 14px;
  background-position: -5175px 0;
}

.iti__flag.iti__ug {
  height: 14px;
  background-position: -5197px 0;
}

.iti__flag.iti__um {
  height: 11px;
  background-position: -5219px 0;
}

.iti__flag.iti__un {
  height: 14px;
  background-position: -5241px 0;
}

.iti__flag.iti__us {
  height: 11px;
  background-position: -5263px 0;
}

.iti__flag.iti__uy {
  height: 14px;
  background-position: -5285px 0;
}

.iti__flag.iti__uz {
  height: 10px;
  background-position: -5307px 0;
}

.iti__flag.iti__va {
  height: 15px;
  background-position: -5329px 0;
}

.iti__flag.iti__vc {
  height: 14px;
  background-position: -5346px 0;
}

.iti__flag.iti__ve {
  height: 14px;
  background-position: -5368px 0;
}

.iti__flag.iti__vg {
  height: 10px;
  background-position: -5390px 0;
}

.iti__flag.iti__vi {
  height: 14px;
  background-position: -5412px 0;
}

.iti__flag.iti__vn {
  height: 14px;
  background-position: -5434px 0;
}

.iti__flag.iti__vu {
  height: 12px;
  background-position: -5456px 0;
}

.iti__flag.iti__wf {
  height: 14px;
  background-position: -5478px 0;
}

.iti__flag.iti__ws {
  height: 10px;
  background-position: -5500px 0;
}

.iti__flag.iti__xk {
  height: 15px;
  background-position: -5522px 0;
}

.iti__flag.iti__ye {
  height: 14px;
  background-position: -5544px 0;
}

.iti__flag.iti__yt {
  height: 14px;
  background-position: -5566px 0;
}

.iti__flag.iti__za {
  height: 14px;
  background-position: -5588px 0;
}

.iti__flag.iti__zm {
  height: 14px;
  background-position: -5610px 0;
}

.iti__flag.iti__zw {
  height: 10px;
  background-position: -5632px 0;
}

.iti__flag {
  height: 15px;
  background-color: #dbdbdb;
  background-image: url("flags.c61229ae.png");
  background-position: 20px 0;
  background-repeat: no-repeat;
  box-shadow: 0 0 1px #888;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("flags@2x.adefcad5.png");
  }
}

.iti__flag.iti__np {
  background-color: rgba(0, 0, 0, 0);
}

:root {
  --bg: #eaeaea;
  --primary: #393939;
  --secondary: #83dfe6;
  --font-color: #727272;
}

body {
  background-color: var(--bg);
  height: 100%;
  min-height: 100vh;
  color: var(--font-color);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

h1, h2, h3 {
  color: var(--primary);
  margin: 0;
  font-family: Prompt, sans-serif;
  font-weight: 600;
  line-height: 1.16;
}

h1 {
  font-size: 45px;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 36px;
  }
}

@media screen and (max-width: 360px) {
  h1 {
    font-size: 32px;
  }
}

h2 {
  font-size: 36px;
}

@media screen and (max-width: 360px) {
  h2 {
    font-size: 32px;
  }
}

h3 {
  font-size: 22px;
}

a {
  color: var(--font-color);
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  width: 100%;
  height: auto;
  line-height: 0;
}

section {
  border-radius: 50px;
  margin: 70px 0;
  padding: 50px;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  section {
    border-radius: 30px;
    margin: 25px 0;
    padding: 25px;
  }
}

.container {
  max-width: 1200px;
  height: inherit;
  margin: 0 auto;
  padding: 0 15px;
}

.wrapper {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.content {
  flex-grow: 1;
  margin-top: 20px;
}

.content_container {
  position: relative;
}

.content_container:before {
  content: "";
  width: 100%;
  height: 150px;
  background-color: #fff;
  border-radius: 40px 40px 0 0;
  position: absolute;
  top: 0;
  left: 0;
}

.d-flex {
  flex-wrap: wrap;
  display: flex;
}

@media screen and (max-width: 768px) {
  .d-flex {
    flex-direction: column;
  }
}

.btn {
  cursor: pointer;
  border-radius: 100px;
  padding: 10px 25px;
  transition: all .3s;
}

.btn_primary {
  border: 1px solid var(--primary);
  background-color: var(--primary);
  color: #fff;
}

.btn_primary:hover:not(:disabled) {
  border-color: var(--secondary);
  background-color: var(--secondary);
  box-shadow: 0 1px 2px rgba(0, 0, 0, .3), 0 1px 3px 1px rgba(0, 0, 0, .15);
}

.btn_primary:focus {
  border-color: var(--secondary);
  background-color: var(--secondary);
}

.btn_primary:disabled {
  cursor: not-allowed;
  background-color: #eaeaea;
  border-color: #eaeaea;
}

.btn_secondary {
  color: var(--primary);
  border: 1px solid var(--primary);
  background-color: #fff;
}

.btn_secondary:hover {
  color: #fff;
  background-color: var(--primary);
}

.underline {
  display: inline-flex;
  position: relative;
}

.underline:after {
  content: "";
  width: 103%;
  height: 3px;
  background-color: var(--secondary);
  border-radius: 3px;
  position: absolute;
  bottom: 0;
  left: -3px;
}

.colored {
  color: var(--secondary);
}

.reverse {
  flex-direction: row-reverse;
}

@media screen and (max-width: 768px) {
  .reverse {
    flex-direction: column;
  }
}

.card {
  border-radius: 20px;
  align-items: flex-end;
  padding: 25px 5px 25px 25px;
  display: flex;
}

@media screen and (max-width: 576px) {
  .card {
    flex-direction: column;
    padding: 25px;
  }
}

.card_content {
  flex-grow: 1;
  align-self: flex-start;
}

.card_title {
  margin-bottom: 10px;
}

.card_icon {
  width: 90px;
  min-width: 90px;
  height: 90px;
  padding-right: 10px;
  position: relative;
}

.card_icon img {
  position: absolute;
  bottom: -25px;
  right: 0;
}

@media screen and (max-width: 576px) {
  .card_icon img {
    position: static;
  }
}

.card p {
  color: var(--primary);
  margin: 0;
  font-size: 14px;
  line-height: 1.4;
}

.blue {
  background-color: var(--secondary);
}

.white {
  background-color: #fff;
}

.green {
  background-color: #32c66a;
}

.yellow {
  background-color: #ffe45e;
}

.header {
  width: 100%;
  z-index: 999;
  background-color: #fff;
  border-radius: 50px;
  padding: 15px 50px;
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
  left: 0;
}

@media screen and (max-width: 768px) {
  .header {
    padding: 15px 25px;
    position: static;
  }
}

@media screen and (max-width: 768px) {
  .header_wrap {
    height: 100%;
    width: 100%;
    z-index: 99;
    background-color: rgba(0, 0, 0, .5);
    transition: all .3s;
    position: fixed;
    top: 0;
    left: -100%;
    transform: translateX(0);
  }
}

.header_wrap.visible {
  transform: translateX(100%);
}

.header_inner {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media screen and (max-width: 768px) {
  .header_inner {
    height: inherit;
    width: 100%;
    max-width: 350px;
    background-color: #fff;
    border-radius: 0 50px 50px 0;
    flex-direction: column;
    align-items: flex-start;
    padding: 70px 20px 35px 35px;
  }
}

.header_list {
  display: flex;
}

@media screen and (max-width: 768px) {
  .header_list {
    flex-direction: column;
  }
}

.header_item {
  margin: 0 8px;
  padding: 10px 12px;
  transition: all .3s;
}

@media screen and (max-width: 768px) {
  .header_item {
    margin: 0;
  }
}

.header_item:hover {
  background-color: #eaeaea;
  border-radius: 50px;
}

.header_item:first-child {
  margin-left: 0;
}

.header_item:last-child {
  margin-right: 0;
}

.header_action {
  cursor: pointer;
  padding: 10px 12px;
  transition: all .3s;
}

.header_action:hover {
  background-color: #eaeaea;
  border-radius: 50px;
}

.header_burger {
  width: 30px;
  height: 24px;
  cursor: pointer;
  z-index: 999;
  transition: all .5s ease-in-out;
  display: none;
  position: relative;
  transform: rotate(0deg);
}

@media screen and (max-width: 768px) {
  .header_burger {
    display: block;
  }
}

.header_burger span {
  height: 3px;
  width: 50%;
  background: var(--primary);
  opacity: 1;
  transition: all .25s ease-in-out;
  display: block;
  position: absolute;
  transform: rotate(0deg);
}

.header_burger span:nth-child(2n) {
  left: 50%;
}

.header_burger span:nth-child(2n+1) {
  left: 0;
}

.header_burger span:first-child, .header_burger span:nth-child(2) {
  top: 0;
}

.header_burger span:nth-child(3), .header_burger span:nth-child(4) {
  top: 10px;
}

.header_burger span:nth-child(5), .header_burger span:nth-child(6) {
  top: 20px;
}

.header_burger.open span:first-child, .header_burger.open span:last-child {
  transform: rotate(45deg);
}

.header_burger.open span:nth-child(2), .header_burger.open span:nth-child(5) {
  transform: rotate(-45deg);
}

.header_burger.open span:first-child {
  top: 5px;
  left: 5px;
}

.header_burger.open span:nth-child(2) {
  top: 5px;
  left: calc(50% - 5px);
}

.header_burger.open span:nth-child(3) {
  opacity: 0;
  left: -50%;
}

.header_burger.open span:nth-child(4) {
  opacity: 0;
  left: 100%;
}

.header_burger.open span:nth-child(5) {
  top: 10px;
  left: 5px;
}

.header_burger.open span:nth-child(6) {
  top: 10px;
  left: calc(50% - 5px);
}

.hero {
  z-index: 1;
  background-color: #fff;
  margin-top: 0;
  position: relative;
  overflow: hidden;
}

.hero:after {
  content: "";
  width: 250px;
  height: 250px;
  background: url("gradient.26d0deb5.png") no-repeat;
  position: absolute;
  bottom: 0;
  right: -3px;
}

.hero_content {
  align-items: center;
}

.hero_desc {
  width: 60%;
}

@media screen and (max-width: 768px) {
  .hero_desc {
    width: 100%;
  }
}

.hero_desc p {
  margin: 15px 0 25px;
  font-family: Prompt, sans-serif;
}

.hero_img {
  width: 40%;
}

@media screen and (max-width: 768px) {
  .hero_img {
    width: 100%;
    justify-content: center;
    margin-top: 15px;
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .hero_img img {
    max-width: 400px;
  }
}

.about {
  background: rgba(255, 255, 255, .5);
}

.about_item {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .about_item {
    width: 100%;
  }
}

.about_item:first-child {
  padding-right: 25px;
}

@media screen and (max-width: 768px) {
  .about_item:first-child {
    padding-right: 0;
  }
}

.about_item a {
  text-decoration: underline;
}

.about_item a:hover {
  text-decoration: none;
}

.custom {
  background-color: #fff;
  position: relative;
}

.custom:after {
  content: "";
  width: 250px;
  height: 250px;
  background: url("gradient.26d0deb5.png") no-repeat;
  position: absolute;
  bottom: 0;
  left: -3px;
  transform: rotateY(180deg);
}

.custom_item {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .custom_item {
    width: 100%;
  }
}

.custom_head span {
  color: var(--secondary);
}

.custom_desc a {
  text-decoration: underline;
}

.custom_desc a:hover {
  text-decoration: none;
}

.custom_img {
  max-width: 400px;
  z-index: 1;
  border-radius: 25px;
  margin-left: auto;
  line-height: 0;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .custom_img {
    margin: 0 auto;
  }
}

.features {
  background-color: rgba(255, 255, 255, .5);
}

.features_head {
  width: 70%;
}

@media screen and (max-width: 768px) {
  .features_head {
    width: 100%;
  }
}

.features_grid {
  margin: 0 -8px;
}

@media screen and (max-width: 768px) {
  .features_grid {
    margin: 0;
  }
}

.features_card-lg {
  width: 100%;
  margin: 15px 0;
}

@media screen and (max-width: 768px) {
  .features_card-lg {
    margin: 0;
  }
}

.features_card-sm {
  width: calc(33.3333% - 16px);
}

@media screen and (max-width: 992px) {
  .features_card-sm {
    width: calc(50% - 16px);
  }
}

.features_card-md {
  width: calc(50% - 16px);
}

.features_card-sm, .features_card-md {
  margin: 8px;
}

@media screen and (max-width: 768px) {
  .features_card-sm, .features_card-md {
    width: 100%;
    margin: 0;
  }
}

.benefits {
  background-color: #fff;
}

.benefits_list {
  width: 75%;
  margin-top: 15px;
}

@media screen and (max-width: 768px) {
  .benefits_list {
    width: 100%;
  }
}

.benefits_list li {
  color: var(--primary);
  margin: 15px 0;
  padding: 0 15px;
  font-family: Prompt, sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.27;
  position: relative;
}

.benefits_list li:before {
  content: "";
  width: 4px;
  height: 4px;
  background-color: var(--primary);
  border-radius: 50%;
  position: absolute;
  top: 13px;
  left: 0;
}

.benefits_img {
  width: 25%;
}

@media screen and (max-width: 768px) {
  .benefits_img {
    width: 100%;
    justify-content: center;
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .benefits_img img {
    max-width: 400px;
  }
}

.case {
  background-color: #fff;
  padding-bottom: 0;
  position: relative;
}

@media screen and (max-width: 768px) {
  .case {
    padding-bottom: 25px;
  }
}

.case:after {
  content: "";
  width: 250px;
  height: 250px;
  background: url("gradient.26d0deb5.png") no-repeat;
  position: absolute;
  bottom: 0;
  right: -3px;
}

.case_item {
  width: 50%;
  display: flex;
}

@media screen and (max-width: 768px) {
  .case_item {
    width: 100%;
  }
}

.case_img {
  z-index: 1;
  line-height: 0;
}

.case_desc-centered {
  align-self: center;
}

.case_desc a {
  text-decoration: underline;
}

.case_desc a:hover {
  text-decoration: none;
}

.solutions {
  background-color: var(--secondary);
}

.solutions_content {
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .solutions_content {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 768px) {
  .solutions_actions {
    margin-top: 15px;
  }
}

.footer {
  background: var(--primary);
  color: #fff;
  text-align: center;
  padding: 15px 0;
}

@media screen and (max-width: 768px) {
  .footer {
    text-align: left;
  }
}

.footer_list {
  justify-content: center;
  display: flex;
}

@media screen and (max-width: 768px) {
  .footer_list {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.footer_list li {
  margin: 0 10px;
  padding: 5px 10px;
}

.footer_list a {
  color: #fff;
}

.footer_copy p {
  margin-bottom: 0;
}

.modal {
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, .5);
  justify-content: center;
  align-items: center;
  transition: visibility 0s linear .25s, opacity .25s, transform .25s;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  transform: scale(1.1);
}

.modal_content {
  background-color: #fff;
  border-radius: 50px;
  padding: 50px 100px;
  position: relative;
}

.modal_form .modal_content {
  max-width: 900px;
  width: 100%;
  align-items: center;
  display: flex;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .modal_form .modal_content {
    flex-direction: column-reverse;
    align-items: center;
    padding: 40px 20px;
  }
}

@media screen and (max-width: 360px) {
  .modal_form .modal_content {
    padding: 20px;
  }
}

.modal_form .modal_content:after {
  content: "";
  width: 250px;
  height: 250px;
  background: url("gradient.26d0deb5.png") no-repeat;
  position: absolute;
  bottom: 0;
  left: -3px;
  transform: rotateY(180deg);
}

@media screen and (max-width: 576px) {
  .modal_form .modal_content:after {
    display: none;
  }
}

.modal_thanks .modal_content {
  max-width: 500px;
}

.modal_show {
  z-index: 99999;
  opacity: 1;
  visibility: visible;
  transition: visibility linear, opacity .25s, transform .25s;
  transform: scale(1);
}

.modal_image, .modal_contact {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .modal_image, .modal_contact {
    width: 100%;
  }
}

.modal_image {
  display: flex;
}

@media screen and (max-width: 576px) {
  .modal_image {
    display: none;
  }
}

.modal_image img {
  max-width: 250px;
  margin-right: auto;
}

@media screen and (max-width: 768px) {
  .modal_image img {
    max-width: 150px;
    margin: 0 auto;
  }
}

.modal_title {
  text-align: center;
  margin-bottom: 50px;
}

@media screen and (max-width: 360px) {
  .modal_title {
    margin-bottom: 20px;
  }
}

.modal_title h2, .modal_thanks .modal_title {
  margin-bottom: 15px;
}

.modal_thanks .btn {
  margin-top: 50px;
}

.contact {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
}

.contact_control {
  margin-bottom: 25px;
  position: relative;
}

.contact_control label {
  color: var(--font-color);
  z-index: 1;
  background-color: #fff;
  padding: 0 5px;
  font-size: 12px;
  line-height: 1;
  display: flex;
  position: absolute;
  top: -6px;
  left: 8px;
}

.contact_field {
  width: 100%;
  border: 1px solid var(--primary);
  border-radius: 10px;
  padding: 12px 15px;
  font-size: 14px;
}

.contact_field:focus {
  outline: 1px solid var(--secondary);
  border: 1px solid var(--secondary);
}

@media screen and (max-width: 768px) {
  .contact_field:focus {
    outline: none !important;
  }
}

.contact_field.phone {
  padding-left: 92px !important;
}

.contact .label {
  opacity: 0;
  transition: all .1s;
}

.contact .label.visible {
  opacity: 1;
}

.contact_action {
  justify-content: center;
  margin-top: 50px;
  display: flex;
}

@media screen and (max-width: 360px) {
  .contact_action {
    margin-top: 30px;
  }
}

.contact_action .btn {
  margin: 0 7px;
}

.iti {
  width: 100%;
}

.iti-mobile .iti--container {
  z-index: 99999;
}

.iti__country-list {
  max-width: 320px;
  max-height: 250px;
  bottom: 0;
}

.iti--separate-dial-code, .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0) !important;
}

/*# sourceMappingURL=index.04e4bf9b.css.map */
