@mixin xs {
    @media screen and (max-width: 360px) {
      @content;
    }
  }

@mixin sm {
    @media screen and (max-width: 576px) {
      @content;
    }
  }

  @mixin md {
    @media screen and (max-width: 768px) {
      @content;
    }
  }

  @mixin lg {
    @media screen and (max-width: 992px) {
      @content;
    }
  }